import {configureStore} from "@reduxjs/toolkit";
import {routerMiddleware} from 'connected-react-router'
import {history} from './root-reducer'
import {createRootReducer} from "./root-reducer"
import {houseListenerMiddleware} from "./house/middleware";
import {orgListenerMiddleware} from "./organization/middleware";
import {translationsListenerMiddleware} from "./translations/middleware";

export default configureStore({
    reducer: createRootReducer(history),

    middleware: getDefaultMiddleware =>
        getDefaultMiddleware({serializableCheck: false})
            .prepend(routerMiddleware(history))
            .prepend(houseListenerMiddleware.middleware)
            .prepend(orgListenerMiddleware.middleware)
            .prepend(translationsListenerMiddleware.middleware)
});
