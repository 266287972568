import React, { useState, useEffect, useRef } from 'react';
import {OverTimeButton} from "../../StyledComponents";
import colors from "../../../styles/colors";

const IdsButtons = ({ ids, selectedIds, selectId }) => {
    const scrollContainerRef = useRef(null);
    const [isOverflowing, setIsOverflowing] = useState(false);
    const [filterAvg, setFilterAvg] = useState(false);

    const checkOverflow = () => {
        const container = scrollContainerRef.current;
        const children = container.children;
        let childrenWidth = 0;
        for (let i = 0; i < children.length; i++) {
            childrenWidth += children[i].offsetWidth;
        }
        setIsOverflowing(childrenWidth > container.clientWidth);
    };

    const shouldFilterAvg = (ids) => {
       // console.log("should filter avg", ids)
        // check if there is more than one id other then "avg"
        let filter = ids.filter(id => id !== "avg");
       // console.log("filter", filter)
        if (filter.length === 1) {
         //   console.log("set filter avg")
            setFilterAvg(true);
        }
    }

    useEffect(() => {
        checkOverflow();
        window.addEventListener('resize', checkOverflow);
        return () => window.removeEventListener('resize', checkOverflow);
    }, []);

    useEffect(() => {
        checkOverflow();
        shouldFilterAvg(ids);
    }, [ids]);

    const scrollContainer = (scrollOffset) => {
        if (scrollContainerRef.current) {
            scrollContainerRef.current.scrollLeft += scrollOffset;
        }
    };

    return (
        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: "space-between" }}>
            {isOverflowing && <OverTimeButton style={{ backgroundColor: colors.lipstick, borderRadius: 0 }} onClick={() => scrollContainer(-100)}>{"<"}</OverTimeButton>}
            <div ref={scrollContainerRef} style={{ overflowX: 'auto', display: 'flex', gap: 15 }} onScroll={checkOverflow}>
                {ids.map((key, i) => {
                    if(filterAvg && key === "avg") {
                        return null;
                    }
                    return (
                        <OverTimeButton
                            style={selectedIds.includes(key) ? {
                                backgroundColor: colors.lipstick,
                                borderRadius: 0
                            } : {borderRadius: 0}}
                            onClick={() => {
                                console.log("select id", key)
                                selectId(key)
                            }}
                        >
                            {key}
                        </OverTimeButton>
                    )
                })}
            </div>
            {isOverflowing && <OverTimeButton style={{ backgroundColor: colors.lipstick, borderRadius: 0 }} onClick={() => scrollContainer(100)}>{">"}</OverTimeButton>}
        </div>
    );
};

export default IdsButtons;
