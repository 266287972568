import { createListenerMiddleware } from '@reduxjs/toolkit'
import {setCurrentLanguage} from "./index";
import {setTableData} from "../table";
import {handleTableLangChange} from "../table/utils";

export const translationsListenerMiddleware = createListenerMiddleware()

translationsListenerMiddleware.startListening({
    actionCreator: setCurrentLanguage,
    effect: async (action, {dispatch, getState}) => {
        console.log('SET current language: ', action.payload)
        const tableData = handleTableLangChange(getState().table.tableData)
        dispatch(setTableData(tableData))
    }
})
